<template>
    <div class="admin_page_body">
        <div class="admin_page_item" style="padding: 60px 30px">
            <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; padding: 0 30px">
                <div style="display: flex; flex-direction: row; align-items: center">
                    <img src="../../../assets/img/adminNotice.png" style="width: 16px; height: 14px" />
                    <span style="font-size: 14px; color: #303133; line-height: 16px; margin-left: 8px; font-weight: bold">商家公告</span>
                </div>

                <span style="font-size: 12px; color: #C0C4CC; line-height: 16px">{{dataInfo.createAt.split('T')[0]}}</span>
            </div>

            <div class="mt20" style="display: flex; flex-direction: row; align-items: center; padding: 0 30px">
                <span style="font-size: 12px; color:#C0C4CC; line-height: 22px; margin-right: 4px">来自：</span>
                <div style="background: #EBF9FF; line-height: 22px; padding: 0 4px; font-size: 12px; color: #333;">{{dataInfo.creatorName === 'admin' ? '系统管理员' : dataInfo.creatorName}}</div>
            </div>

            <div class="mt20" style="padding: 0 30px">
                <h1 style="font-size: 14px; color: #303133; line-height: 22px">{{dataInfo.title}}</h1>
            </div>

            <div class="mt24" style="background: #F8F8F8; padding: 20px 30px" v-html="dataInfo.content"></div>

            <div class="page_footer" style="padding: 0 30px; margin-top: 60px; justify-content: flex-end">
                <el-button type="default" size="small" @click="$router.go(-1)">返 回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
  import {getAdminNoticeDetail, getAdminNoticeLength} from "../../../api/admin/notice";
  export default {
    name: "adminNoticeDetail",
    data () {
      return {
        isRequestLoading: false,
        dataInfo: {
          createAt: ''
        }
      }
    },
    created() {
      this.getInfo()
    },
    methods: {
      getInfo () {
        this.isRequestLoading = true
        getAdminNoticeDetail(this.$route.query.id).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            this.dataInfo = res.data;
            this.getNoticeLength()
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getNoticeLength () {
        getAdminNoticeLength().then(res => {
          if (res.code === 0) {
            this.$store.commit('changeNoticeLength', res.data)
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .data_table a{
      &:hover{
          div, span{
              color: #5DAD03 !important;
          }
      }
    }
</style>
